require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")

const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

// Usar a função import() separa os assets em chuncks assíncronos
import('popper.js')
import('bootstrap')

import '../stylesheets/base.scss'

// importante para conectar com turbo-ios
import * as Turbo from "@hotwired/turbo"
window.Turbo = Turbo

import Rails from '@rails/ujs'
window.Rails = Rails

import { Tab } from 'bootstrap'
window.Tab = Tab

import $ from 'jquery'
window.$ = $

// utils

window.onTurboLoad = (aHandler) => {
  document.addEventListener('turbo:load', () => {
    if (aHandler) {
      aHandler.apply(this)
    }
  })
}

window.onLoad = (aHandler) => {
  document.addEventListener('DOMContentLoaded', () => {
    if (aHandler) {
      aHandler.apply(this)
    }
  })
}
